<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }}) </small>
        {{ id ? data.name : "Новый пользователь" }}
      </template>
      <a-loader v-if="!loaded" />
      <div v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true, hideDetails: 'auto' }" @validate="validate($event)" />
      </div>
      <template v-slot:actions>
        <v-spacer />
        <a-btn-save @click="submit()" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "../../components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    api: String,
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.users,
      removeDialogShow: false,
      configDialpgShow: false,
      loaded: false,
    };
  },
  created() {},
  watch: {
    value() {
      if (this.value) this.updateData(this.id);
    },
  },
  computed: {
    model() {
      const model = [
        { name: "buying.userRoles", title: "Покупки. роль кому назначено", type: "select", chips: true, options: "roles" },
        { name: "buying.userAccess", title: "Покупки. Пользователь кому назначено", type: "select", dir: "user", chips: true },
        { name: "buying.createdByRoles", title: "Покупки. роль кем создано", type: "select", chips: true, options: "roles" },
        { name: "buying.createdByAccess", title: "Покупки. Пользователь кем создано", type: "select", dir: "user", chips: true },
        { name: "tasks.userRoles", title: "Задачи. роль кому назначено", type: "select", chips: true, options: "roles" },
        { name: "tasks.userAccess", title: "Задачи. Пользователь кому назначено", type: "select", dir: "user", chips: true },
        { name: "tasks.createdByRoles", title: "Задачи. роль кем создано", type: "select", chips: true, options: "roles" },
        { name: "tasks.createdByAccess", title: "Задачи. Пользователь кем создано", type: "select", dir: "user", chips: true },
      ];
      return model;
    },
  },
  methods: {
    async fetchData(id) {
      if (id) {
        this.loading = true;
        let response = await this.$axios.get(this.api + "/" + id);
        let data = response.data.data;
        data = data?.data?.access;
        for (const name in data) {
          if (name == "buying" && this.data?.[name] && data[name]) {
            for (const nameData in data[name]) {
              this.$set(this.data[name], nameData, data[name][nameData]);
              this.$set(this.errors, nameData, null);
            }
          } else {
            this.$set(this.data, name, data[name]);
            this.$set(this.errors, name, null);
          }
        }
        this.loading = false;

        this.afterFetchData(response);
      } else this.afterFetchData();
    },
    afterFetchData(r) {
      // this.calcModel(this.data?.type);
      //   this.getModel();
      this.loaded = true;
    },
    async submit() {
      await this.beforeSave();
      if (this.saving) return;
      this.saving = true;
      if (await this.validateAll(this.data)) {
        const data = { id: this.id, data: { access: this.data } };
        await this.post(data, this.api);
        this.saving = false;
        return true;
      }
      console.log("save2");

      this.saving = false;
      return false;
    },
    afterSave(data, status) {
      if (status) {
        this.$emit("input");
      }
    },
  },
};
</script>
